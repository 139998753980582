<template>
  <ElConfigProvider :locale="locale" size="small" :button="button">
    <router-view/>
  </ElConfigProvider>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const locale = ref(zhCn)
const button = reactive({
  autoInsertSpace: true
})
</script>
