import { createStore } from 'vuex'
import { reactive } from 'vue'

const store = createStore({
  state: reactive({}),
  mutations: {},
  actions: {},
  getters: {}
})

export default store
