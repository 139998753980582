import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { reactive, ref } from 'vue'
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const locale = ref(zhCn)
const button = reactive({
  autoInsertSpace: true
})

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(ElConfigProvider), {
    locale: locale.value,
    size: "small",
    button: button
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["locale", "button"]))
}
}

})