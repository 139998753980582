import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/zh-cn'

/* 引入 Element-plus 及其样式 */
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'

/* 引入el-icon图标 */
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

/* 引入项目私有 CSS */
import './index.css'
import '@/style/animate.css'
import '@/style/basic.css'
import '@/style/newMenu.css'
import '@/style/newTabs.css'
import '@/style/transition.css'
import 'animate.css'

axios.defaults.baseURL = '/api'

const app = createApp(App)

for (const iconName in ElementPlusIconsVue) {
  // @ts-ignore
  app.component(iconName, ElementPlusIconsVue[iconName])
}

dayjs.extend(weekday)
dayjs.locale('zh-cn')
app.config.globalProperties.$dayjs = dayjs

app.use(ElementPlus, { size: 'small' })
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')
