export const routes = [
  {
    path: '/',
    redirect: '/stock'
  },
  {
    path: '/stock',
    component: () => import('@/views/stock/pageIndex.vue'),
    meta: { id: 0, label: '登录页', disabled: false, type: 'login' }
  },
  {
    path: '/test',
    component: () => import('@/views/test/testDemo.vue'),
    meta: { id: 0, label: '测试页', disabled: false, type: 'test' }
  }
]
